// export const serif = `
//   font-family: Georgia;
// `

export const NunitoSansExtraLight = `
font-family: 'Nunito Sans', sans-serif;
font-weight: 200;
`

export const NunitoSansLight = `
font-family: 'Nunito Sans', sans-serif;
font-weight: 300;
`

export const NunitoSans = `
font-family: 'Nunito Sans', sans-serif;
font-weight: 400;
`

export const NunitoSansSemiBold = `
font-family: 'Nunito Sans', sans-serif;
font-weight: 600;
`

export const NunitoSansBold = `
font-family: 'Nunito Sans', sans-serif;
font-weight: 700;
`


export const RobotoCondensedLight = `
font-family: 'Roboto Condensed', sans-serif;
font-weight: 300;
`

export const RobotoCondensedBold = `
font-family: 'Roboto Condensed', sans-serif;
font-weight: 700;
`

export const DidactGothic = `
font-family: 'Didact Gothic', sans-serif;
font-weight: 400;
`
