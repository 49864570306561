import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"
import {

  DidactGothic,
  accent,
  above,
} from "../utilities"
const GlobalStyle = createGlobalStyle`

${normalize()}

body{
  margin:0;
}

html{
 
  ${DidactGothic};
  font-size: 16px;
  color:#111;
}

*{
box-sizing:border-box;
}

.container{
  
  margin: 0 auto;
  padding:0 20px;
  width:100%;
  ${above[1200]`
  width:1100px;
  `};
 
}

h1, h2, h3{
  
  ${DidactGothic};
  color:#333;
  font-size:1.7rem;
}

p{
  line-height:170%;
}

a{
  transition: color 0.3s ease;
  &:hover{
    color:${accent};
  }
}

img{
  max-width:100%;
}

.wow{
  visibility:hidden;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}


`

export default GlobalStyle
