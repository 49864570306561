import { css } from "styled-components"

//http://bradfrost.com/blog/post/7-habits-of-highly-effective-media-queries/ - use ems, add more breakpoints major minor
const sizes = {
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
  1000: 1000,
  1100: 1100,
  1200: 1200,
  1300: 1300,
  1400: 1400,
  1500: 1500,
  1600: 1600,
  1700: 1700,
  1800: 1800,
}

// creates obj with properties as media query functions that take css as args
// above.large // above.med //above.small
// how to use  ${above.med`color: blue;`} , backticks - no parens
export const above = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const below = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})
